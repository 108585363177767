import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TwoColumn from "../../components/two-column"
import CustomerJourney from '../../components/customer-journey'
import Gateways from "../../components/gateways"

// import { Helmet } from 'react-helmet'

const bannerContent = {
  title: "Don't get tied down to one payment gateway. Discover the Billsby Vault.",
  description: [
    {
      text: 'As you grow your business, you\'ll want the flexibility to switch payment gateways without costly migrations. Otherwise you might find yourself trapped with one gateway partner and unable to negotiate effectively.'
    }
  ],
  withPromotion: false,
  textColor: "#252422",
  backgroundColor: "#F3F5FB",
  hasBg: false,
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  isBanner: true,
  image: require('../../images/billsby-payment-gateways.svg'),
  imageName: 'billsby banner image',
  svg: true,
  sectionPaymentGateways: true,
}

const billsbyVault = {
  title: "Cards are securely tokenized in the Billsby Vault.",
  list: [
    {
      title: 'We\'ll take care of your cards',
      description: <>When customers provide a payment token, we'll store it securely in the Billsby Vault - a PCI-DSS compliant solution that sits between you and your gateway.</>,
    },
    {
      title: 'You won\'t touch your cardholder data',
      description: <>Across both of our checkout solutions, you don't need to touch, process or store cardholder data - so you'll qualify for the simplest level of PCI DSS paperwork.</>,
    },
    {
      title: 'Never worry about gateway migrations.',
      description: <>Because your cards are stored in the Billsby Vault, you'll never need to migrate tokens from one payment gateway to another.</>,
    }
  ],
  image: require('../../images/billsby-payment.svg'),
  svg: true,
  imageName: 'billsby-payment'
}

const switchPaymentGateway = {
  title: "Switch payment gateways at any time with one click.",
  list: [
    {
      title: 'Change gateways within your control panel',
      description: <>If you get a better processing deal, you can swap all processing from one gateway to another in one click from your control panel.</>,
    },
    {
      title: 'Choose a different gateway for each currency',
      description: <>Want to transact using Stripe for USD, Adyen for GBP and Checkout.com for EUR? No problem. You can pick a separate gateway for each currency.</>,
    },
    {
      title: 'Your customers won\'t know there\'s been a change.',
      description: <>Because cards are stored in the Billsby Vault, there's no need for your customers to provide card details again or revalidate - everything just works seamlessly.</>,
    }
  ],
  align: 'right',
  image: require('../../images/billsby-checkout.svg'),
  imageName: 'billsby-checkout',
  svg: true,
}

const paymentIndustryCompliance = {
  title: "Payment industry compliance is done for you, too.",
  list: [
    {
      title: 'Reduce your PCI-DSS compliance burden',
      description: <>With Billsby, cards are securely tokenised in the Billsby Vault - whichever integration approach you choose you'll never be in possession of a customers payment information. This keeps your PCI-DSS compliance burden as low as possible, with quick and simple implementation.</>,
    },
    {
      title: 'Get ready for SCA',
      description: <>SCA, or Secure Card Authentication, is the next big piece of legislation that'll affect your card payments and subscription customers. If you're using Billsby Checkout, SCA compliance will be automatically enabled on your website as it becomes necessary with no development work required.</>,
    }
  ],
  image: require('../../images/billsby-compliance.svg'),
  imageName: 'billsby-compliance',
  svg: true
}

const takeYourTokens = {
  title: "If you ever want to leave, you can take your tokens with you.",
  description: [
    {
      text: "We won't hold your tokens to ransom. If you ever want to leave Billsby we'll work with your new PCI-DSS compliant provider to arrange a token migration, and you can access all of your other data from our API."
    },
    {
      text: "For our part, we'll only charge for this service based on what it actually costs us to deliver it. It's just about doing the right thing."
    }
  ],
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../images/doggie@2x.png'),
  backgroundPosition: 'right',
  sectionProtection: true,
}


const gatewaysContent = {
  title: "Native support for a wide variety of payment gateways",
  text: "Billsby supports a broad and growing range of payment gateways, so you can choose the processor that's the best fit for your business. Support for other payment methods (like Direct Debit and ACH) is coming soon.",
  block: [
    {
      img: 'adyen.png',
      brandName: "Adyen",
    },
    {
      img: 'authorize.net.png',
      brandName: "authorize.net",
    },
    {
      img: 'braintree.png',
      brandName: "checkout.com",
    },
    {
      img: 'checkout.png',
      brandName: "Braintree",
    },
    {
      img: 'ixopay.png',
      brandName: "IXOPAY",
    },
    {
      img: 'stripe.png',
      brandName: "Stripe",
    },
  ]
}


const PaymentGateways = () => {
  return (
    <Layout className="payment-gateway">
      <SEO 
        title="Payment Gateways | Billsby | Powerful, customizable subscription billing software" 
        description="Need subscription billing software integrated with Adyen, Authorize.net, Braintree, Checkout.com and Stripe with full PCI-DSS compliance? We've got you covered." 
        url="https://www.billsby.com/product/payment-gateways"
      />

      <TwoColumn content={bannerContent}/>
      <CustomerJourney content={billsbyVault} />
      <CustomerJourney content={switchPaymentGateway} />
      <Gateways content={gatewaysContent} />
      <CustomerJourney content={paymentIndustryCompliance} />
      <TwoColumn content={takeYourTokens}/>
    </Layout>
  )
}

export default PaymentGateways
